import React from "react"

import EmptyLayout from "@components/empty-layout"
import SEO from "@components/seo"

const VIPiPhonePage = () => (
  <EmptyLayout bodyClassName='coachSignup'>
    <SEO 
      title="Coach Is At Max Capacity"
      description="Harper Coach is 1:1 remote training with a real dog trainer, powered by an awesome app, dedicated to helping you and your pup reach your goals." 
    />
  
    <section className="stripe" id="coachForm">
      <div className="container">
        <a href="/coach/" className="backArrowButton">Go back</a>
        <h2 className="processSubhead">Program Full</h2>
        <h1 className="sectionTitle">Coach is currently at max capacity</h1>
        <p className="prose">We&rsquo;re sorry, but Harper Coach is currently full. We can notify you once we have room!</p>
        <a href="/coach/signup/notify/" className="btn btn--primary btn--block">Notify me when a spot opens up</a>
      </div>
    </section>
  </EmptyLayout>
);

export default VIPiPhonePage
